
<script setup lang="ts">
    import { provide, computed, watch, ref } from "vue";
    import { useZIndex } from "./ZIndex.ts";

    import MActionSheetBtn from "./components.ActionSheetBtn.vue";

    export interface IProps {
        title?: string | string[];
        reverse?: boolean;
    };

    const props = defineProps<IProps>();
    const model = defineModel<boolean>();

    const backdrop_zindex = useZIndex(model);
    const modal_zindex = useZIndex(model);

    const titles = computed(() => (props.title instanceof Array) ? props.title : [props.title]);

    const actions = ref<HTMLElement>();

    provide("action-sheet", model);

    function onClickSheet(e) {
        const button = e.target.closest("button");
        if (button && button.classList.contains("afm2-action-sheet-btn") && !button.disabled) {
            model.value = false;
        }
    }
    
    watch(model, (val) => {
        if (actions.value) {
            actions.value.scrollTop = actions.value.scrollHeight;
        }
    });

</script>

<template>
    <div style="position: absolute;">
        <teleport to="body">
            <div style="position: absolute;">
                <!-- backdrop -->
                <div
                    class="afm2-action-sheet-backdrop"
                    :class="{ 'show': model }"
                    style="position: fixed; inset: 0; background-color: rgba(0%, 0%, 0%, 40%);"
                    :style="{ 'z-index': backdrop_zindex }"
                    @click="$emit('update:modelValue', false)">
                </div>

                <!-- sheet -->
                <div
                    class="afm2-action-sheet-modal"
                    :class="{ 'show': model }"
                    style="position: fixed; left: 0; right: 0; bottom: 0;"
                    :style="{ 'z-index': modal_zindex }"
                    @click="onClickSheet"
                >
                    <div class="mx-2 mb-2 afm2-action-sheet-container d-flex" :class="{ 'flex-column': !reverse, 'flex-column-reverse': reverse }" ref="actions">
                        <template v-if="title">
                            <div class="d-flex flex-column justify-content-center align-items-center gap-1 border-bottom fw-medium text-muted font-2" style="background-color: rgb(96%, 96%, 96%); border-radius: 0.5rem 0.5rem 0 0; padding: 0.75rem;">
                                <template v-for="title in titles">
                                    <div class="text-center">{{ title }}</div>
                                </template>
                            </div>
                        </template>
                        <template v-else-if="$slots.header">
                            <div class="border-bottom" style="color: rgb(60%, 60%, 60%); font-size: 0.9em; background-color: rgb(96%, 96%, 96%); border-radius: 0.5rem 0.5rem 0 0;">
                                <slot name="header" />
                            </div>
                        </template>
                        <slot />
                    </div>
                    <div class="mx-2 mb-2 afm2-action-sheet-container">
                        <MActionSheetBtn class="fw-medium" :text="$t('Cancel')" />
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<style>
    .afm2-action-sheet-container {
        max-height: calc((3em * 10) + 2em);
        overflow-y: auto;
        /* border-radius: 0 0 0.5rem 0.5rem; */

        > .afm2-action-sheet-btn {
            position: relative;
            display: flex;
            justify-content: left;
            align-items: center;
            flex-shrink: 0;
            

            color: black;
            background-color: rgb(96%, 96%, 96%);
            width: 100%;
            border: none;
            font-size: 1.0625em;
            font-size: 1.125em;
            color: #3575B3;
            color: #3b82f6;
            color: rgb(20%, 20%, 20%);
            color: #0ea5e9;
            color: var(--bs-black);
            height: 3em;

            padding: 0 1.5rem;

            transition: all 200ms ease-in-out;

            &:first-child:last-child {
                border-radius: 0.5rem;
            }

            &:active:not(:disabled) {
                background-color: rgb(90%, 90%, 90%);
                transition: all 0ms ease-in-out;
            }
            &:disabled {
                background-color: var(--bs-gray-100);
                color: var(--bs-gray-500);
                pointer-events: none;
            }

            > i {
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translate(0%, -50%);
                font-size: 1.375em;
            }
        }
    }

    .afm2-action-sheet-container.flex-column > .afm2-action-sheet-btn {
        &:not(:last-child) {
            border-bottom: 1px solid rgb(90%, 90%, 90%);
        }
        &:first-child {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }
        &:last-child {
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }
    }

    .afm2-action-sheet-container.flex-column-reverse > .afm2-action-sheet-btn {
        &:not(:first-child) {
            border-bottom: 1px solid rgb(90%, 90%, 90%);
        }
        &:last-child {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }
        &:first-child {
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }
    }
</style>

<style scoped>
    .afm2-action-sheet-backdrop {
        transition: all 300ms ease-in-out;

        pointer-events: none;
        opacity: 0%;

        &.show {
            pointer-events: auto;
            opacity: 100%;
        }
    }

    .afm2-action-sheet-modal {
        transition: all 300ms ease-in-out;

        transform: translate(0, 25%);
        opacity: 0%;
        pointer-events: none;

        &.show {
            pointer-events: auto;
            opacity: 100%;
            transform: translate(0, 0);
        }
    }
    
    .raise-enter-from {
        transform: translate(0, 25%);
        opacity: 0%;
    }
    .raise-enter-active {
        transition: all 300ms ease-in-out;
    }
    .raise-enter-to {
    }
    .raise-leave-from {
    }
    .raise-leave-active {
        transition: all 300ms ease-in-out;
    }
    .raise-leave-to {
        transform: translate(0, 25%);
        opacity: 0%;
    }
</style>
